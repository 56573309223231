import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout';
Vue.use(Router);
const routes = [{
  path: '/',
  component: Layout,
  redirect: '/frontpage/banner',
  meta: {
    title: 'routes.frontpage.name',
    icon: 'frontpage'
  },
  children: [{
    path: 'frontpage/banner',
    component: () => import('@/views/frontpage/banner'),
    meta: {
      title: 'routes.frontpage.banner'
    }
  }, {
    path: 'frontpage/category',
    component: () => import('@/views/frontpage/category'),
    meta: {
      title: 'routes.frontpage.category'
    }
  }, {
    path: 'frontpage/recipe',
    component: () => import('@/views/frontpage/recipe'),
    meta: {
      title: 'routes.frontpage.recipe'
    }
  }]
}, {
  path: '/categories',
  component: Layout,
  redirect: '/categories',
  children: [{
    path: '',
    component: () => import('@/views/categories/index'),
    meta: {
      title: 'routes.categories',
      icon: 'category'
    }
  }]
}, {
  path: '/products',
  component: Layout,
  redirect: '/products',
  children: [{
    path: '',
    component: () => import('@/views/products/index'),
    meta: {
      title: 'routes.products',
      icon: 'product'
    }
  }]
}, {
  path: '/recipe',
  component: Layout,
  redirect: '/recipe',
  children: [{
    path: '',
    component: () => import('@/views/recipe/index'),
    meta: {
      title: 'routes.recipe.name',
      icon: 'recipe'
    }
  }, {
    path: 'create',
    component: () => import('@/views/recipe/create'),
    meta: {
      title: 'recipe.create',
      noCache: true,
      activeMenu: '/recipe'
    },
    hidden: true
  }, {
    path: 'detail',
    component: () => import('@/views/recipe/detail'),
    meta: {
      title: 'recipe.detail',
      noCache: true,
      activeMenu: '/recipe'
    },
    hidden: true
  }]
}, {
  path: '/users',
  component: Layout,
  redirect: '/users',
  children: [{
    path: '',
    component: () => import('@/views/users/index'),
    meta: {
      title: 'routes.users.name',
      icon: 'user'
    }
  }, {
    path: 'detail',
    component: () => import('@/views/users/detail'),
    meta: {
      title: 'routes.users.detail',
      noCache: true,
      activeMenu: '/users'
    },
    hidden: true
  }]
}, {
  path: '/feedback',
  component: Layout,
  redirect: '/feedback',
  children: [{
    path: '',
    component: () => import('@/views/feedback/index'),
    meta: {
      title: 'routes.feedback.name',
      icon: 'feedback'
    }
  }, {
    path: 'detail',
    component: () => import('@/views/feedback/detail'),
    meta: {
      title: 'routes.feedback.detail',
      noCache: true,
      activeMenu: '/feedback'
    },
    hidden: true
  }]
}, {
  path: '/note',
  component: Layout,
  redirect: '/note',
  children: [{
    path: '',
    component: () => import('@/views/note/index'),
    meta: {
      title: 'routes.note.name',
      icon: 'note'
    }
  }, {
    path: 'detail',
    component: () => import('@/views/note/detail'),
    meta: {
      title: 'routes.note.detail',
      noCache: true,
      activeMenu: '/note'
    },
    hidden: true
  }]
}, {
  path: '/tag',
  component: Layout,
  redirect: '/tag',
  children: [{
    path: '',
    component: () => import('@/views/tag/index'),
    meta: {
      title: 'routes.tag',
      icon: 'tag'
    }
  }]
}, {
  path: '/settings',
  component: Layout,
  redirect: '/settings/account',
  meta: {
    title: 'routes.settings.name',
    icon: 'settings'
  },
  children: [{
    path: 'settings/account',
    component: () => import('@/views/settings/account'),
    meta: {
      title: 'routes.settings.account'
    }
  }, {
    path: 'settings/other',
    component: () => import('@/views/settings/other'),
    meta: {
      title: 'routes.settings.other'
    }
  }]
}]; // 404 page must be placed at the end !!!

routes.push({
  path: '*',
  redirect: '/404',
  hidden: true
});
export const asyncRoutes = routes;
/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export const constantRoutes = [{
  path: '/login',
  component: () => import('@/views/login/index'),
  hidden: true
}, {
  path: '/404',
  component: () => import('@/views/404'),
  hidden: true
}];

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
});

const router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;