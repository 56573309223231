//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path;
    }

  }
};